import { useEffect, useState, useRef } from "react";
import {  Card,  FormControl,  FormControlLabel,  Box, FormLabel,
  Grid,  IconButton,  Radio,  RadioGroup,  TextField,  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Progress from "../Generales/Progress";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {llamaApiFormData3,ErrorGeneral } from '../funciones/ClienteAxios';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { v4 as uuidv4 } from 'uuid';
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
  statusCard: {
    textAlign: "center",
    color: "white",
    marginRight: 5, //padding: '24px 12px',
    height: "100%",
    paddingLeft: 0.5 + "rem",
    paddingRight: 0.5 + "rem",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const CardDet = ({  element,  index, beca, banderaSubir  }) => {
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source();   
  const inputRef = useRef(null);
  const idusu= localStorage.getItem('UsuId');
  const acceptFile="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  
  const [available, setAvailable] = useState(true);    
  const [espera, setEspera] = useState(true);
  const [arraySubidos, setArraySubidos] = useState([])
  const [arrayPreview, setArrayPreview] = useState([])
  const [actividad, setActividad] = useState({  });
  const [progresoEnvio, setProgresoEnvio] = useState(50);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //console.log(element);
    
    return () => {
      source.cancel();
    };
  }, [index]);

  useEffect(()=>{
    if (banderaSubir === true && arraySubidos.length !== 0 && arraySubidos[0].size > 0) 
    {
      registrarEvid()
    }
  },[banderaSubir])

  const registrarEvid=()=>{    
    let auxSize =0   
    let bodyFormData = new FormData();
    bodyFormData.append('Usuario',idusu)
    bodyFormData.append('Llave',beca.Id)
    bodyFormData.append('Descr',"")    
    bodyFormData.append('Tipo',element.Tipo)    
    bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')
    //bodyFormData.append('Status',0)
    //bodyFormData.append('descr',"")
    arraySubidos.forEach((img)=>{
      auxSize=auxSize+img.size
      bodyFormData.append('multi-files',img)
    })
    const headers= { 'tipo': "SPACE", "clase": "PERFIL",
      "size":auxSize, "perfil":beca.Id
    }        
    const url = "/registro/v1/file/upload";
    function respuesta(auxiliar) {
      if (auxiliar.success===true) 
      {  
        setProgresoEnvio(0)
        const mensaje=`Se subio ${beca.TipoNom} correctamente`        
        toast.success(`${mensaje}`,{
          pauseOnFocusLoss: false,
          theme: "colored",
          toastId: `exito-env`
        })                                                                                                                             
      }       
     }    
    llamaApiFormData3(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,setProgresoEnvio,headers);
  }                    

  const handleChange = function (event) {
    const regex = /([a-zA-Z0-9\s_.\-():])+(.pdf)$/
    let image = event.target.files[0]
    let auxCode = uuidv4();
    if (image && image.name.toLowerCase().match(regex)) {
      let auxNombre = auxCode + image.name.slice(image.name.lastIndexOf("."), image.name.length);
      let myNewFile = new File([image], auxNombre, {type: image.type});     
      
      setArraySubidos([...arraySubidos, myNewFile])
      setArrayPreview({name: image.name})
    } else {
      let nombre = "";
      if (image.name.length >= 25) 
      { nombre = image.name.substring(0, 24) + "..." + image.name.substring(image.name.lastIndexOf("."), image.name.length);
      } 
      else 
      { nombre = image.name;
      }
      let mensaje = `Formato de archivo incorrecto en ${nombre} seleccione una imagen tipo pdf.`
      toast.warning(mensaje, {
        pauseOnFocusLoss: false,
        theme: "colored",
        toastId: `incorrecto-sel`
      });
    }
  }


  const btnCancelar = () => {
    setArraySubidos([])
    setArrayPreview([])
    //setAvailable(false);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const btnSubir=()=>{
    return(
    <Grid>
      <form id="form-file-upload">
        <input ref={inputRef} type="file" accept={acceptFile} id="input-file-upload" 
          onChange={handleChange}/>
        <label id="label-file-upload" htmlFor="input-file-upload" className={ ""}>                                    
          <IconButton  //disabled={espera ? true : false} 
            onClick={onButtonClick}>
            <CloudUploadIcon/>
          </IconButton>                        
        </label>
      </form>    
    </Grid>
    )
  }

  const tamMax =80

  return (
    <div key={index} style={{ overflowY: "auto", width:"100%" }}>
      <Card
        className={classes.marginBottom}
        style={{ padding: 0, height: tamMax, }}
      >
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={2}>
            <Card
              className={classes.statusCard}
              style={{ backgroundColor: "#049b4f",textAlign:"center",justifyContent:"center",
                height: tamMax, alignItems: "center", display: "flex",
              }}
            >
              <Typography component="p" variant="body2"   >
                Pendiente                                  
              </Typography>
            </Card>
          </Grid> 
   
          
          <Grid item xs={4}>
            <Typography component="p" variant="h6" style={{fontSize:"12px"}}>
              {element.TipoNom}                                  
            </Typography>
          </Grid>          
          <Grid item xs={6} style={{ marginLeft: "auto" }}>
         
            <Grid  >              
              {arrayPreview.length !== 0 ?
                <Typography  style={{fontSize:"12px"}}>
                  {arrayPreview.name}
                </Typography>              
              : null
              }
            </Grid>
            {!espera ? (
            <Box display={"flex"} >
              <Grid  >         
                <input ref={inputRef} type="file" accept={acceptFile} id="input-file-upload" 
                  onChange={handleChange} hidden/>                                            
                  <IconButton  id="label-file-upload" htmlFor="input-file-upload" className={ ""} disabled={espera ? true : false} 
                    onClick={onButtonClick}  size={"small"}>
                    <CloudUploadIcon/>
                  </IconButton>                                               
              </Grid>
              {arrayPreview.length !== 0 ?
               <Grid item>
                <IconButton
                  size={"small"} aria-label="delete" disabled={espera ? true : false} 
                  onClick={(e) => btnCancelar()} style={{marginLeft:"1rem"}}
                >
                  <CancelIcon size="small" color={"error"} />
                </IconButton>
              </Grid>
              :null}
            </Box>)
            : (progresoEnvio > 0 ? (
               <Box width={"80%"}> <Progress progress={progresoEnvio}/> 
               </Box>)
              :null)
          }
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CardDet;
